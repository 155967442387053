<template>
    <div class="w-full py-[64px] bg-[#0A2A9E] px-4">
        <div class="grid grid-cols-12 items-center justify-center">
            <div class="md:col-span-4 md:col-start-2 max-sm:col-span-12">
                <div class="w-full">
                    <img :src="logo" class="mb-2" width="200" height="50" />
                    <p class="p2-light text-white">{{ spec.hotelzero_desc }}</p>
                </div>
            </div>

            <div></div>

            <div class="md:col-span-5 max-sm:col-span-12">
                <div class="w-full">
                    <p class="p2-light text-white">{{ spec.copyright }}</p>
                    <div class="flex gap-1">
                        <a :href="spec.termsUrl">
                            <p class="text-white underline">{{ spec.terms }}</p>
                        </a>
                        <p class="text-white">&bull;</p>
                        <a :href="spec.privacyUrl">
                            <p class="text-white underline"> {{ spec.privacy }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "../../../../../assets/images/logo_white.svg";

export default {
    props: {
        spec: {
            type: Object
        }
    },
    data() {
        return {
            logo: logo
        };
    }
};
</script>

<style>
@media (max-width: 640px) {
    .footer-logo {
        max-width: 200px;
    }
}
</style>
